// Generated by Framer (7aa0232)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["KB2MgvewJ", "L2HNxyDrO"];

const variantClassNames = {KB2MgvewJ: "framer-v-lcqfnv", L2HNxyDrO: "framer-v-1k9fbm9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"chart-pie/dark": "KB2MgvewJ", "chart-pie/light": "L2HNxyDrO"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "KB2MgvewJ"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "KB2MgvewJ", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-zoAsf", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-lcqfnv", className)} data-framer-name={"chart-pie/dark"} layoutDependency={layoutDependency} layoutId={"KB2MgvewJ"} ref={ref} style={{...style}} {...addPropertyOverrides({L2HNxyDrO: {"data-framer-name": "chart-pie/light"}}, baseVariant, gestureVariant)}><SVG className={"framer-1j0pf5h"} data-framer-name={"Icon"} fill={"rgba(0,0,0,1)"} intrinsicHeight={22} intrinsicWidth={23} layoutDependency={layoutDependency} layoutId={"I8821:7042;102:579"} svg={"<svg width=\"23\" height=\"22\" viewBox=\"0 0 23 22\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M0.95166 11.1601C0.95166 5.34563 5.66521 0.63208 11.4797 0.63208V11.1601H22.0077C22.0077 16.9745 17.2941 21.6881 11.4797 21.6881C5.66521 21.6881 0.95166 16.9745 0.95166 11.1601Z\" fill=\"#2E5061\"/>\n<path d=\"M14.1117 0.96365C17.8113 1.91587 20.7239 4.8285 21.676 8.52814H14.1117V0.96365Z\" fill=\"#2E5061\"/>\n</svg>\n"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-zoAsf [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-zoAsf .framer-1973bs6 { display: block; }", ".framer-zoAsf .framer-lcqfnv { height: 27px; overflow: hidden; position: relative; width: 27px; }", ".framer-zoAsf .framer-1j0pf5h { bottom: 3px; flex: none; left: 2px; position: absolute; right: 4px; top: 2px; }", ".framer-zoAsf.framer-v-1k9fbm9 .framer-lcqfnv { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 27px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 26.5
 * @framerIntrinsicWidth 26.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"L2HNxyDrO":{"layout":["fixed","fixed"]}}}
 */
const FramerpblHZJzJO: React.ComponentType<Props> = withCSS(Component, css, "framer-zoAsf") as typeof Component;
export default FramerpblHZJzJO;

FramerpblHZJzJO.displayName = "Icon/Solid/chart-pie";

FramerpblHZJzJO.defaultProps = {height: 26.5, width: 26.5};

addPropertyControls(FramerpblHZJzJO, {variant: {options: ["KB2MgvewJ", "L2HNxyDrO"], optionTitles: ["chart-pie/dark", "chart-pie/light"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerpblHZJzJO, [])